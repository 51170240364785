exports.components = {
  "component---src-pages-home-home-js": () => import("./../../../src/pages/home/home.js" /* webpackChunkName: "component---src-pages-home-home-js" */),
  "component---src-pages-home-sections-about-section-about-section-js": () => import("./../../../src/pages/home/sections/about-section/about-section.js" /* webpackChunkName: "component---src-pages-home-sections-about-section-about-section-js" */),
  "component---src-pages-home-sections-contact-section-contact-section-js": () => import("./../../../src/pages/home/sections/contact-section/contact-section.js" /* webpackChunkName: "component---src-pages-home-sections-contact-section-contact-section-js" */),
  "component---src-pages-home-sections-footer-section-footer-section-js": () => import("./../../../src/pages/home/sections/footer-section/footer-section.js" /* webpackChunkName: "component---src-pages-home-sections-footer-section-footer-section-js" */),
  "component---src-pages-home-sections-title-section-title-section-js": () => import("./../../../src/pages/home/sections/title-section/title-section.js" /* webpackChunkName: "component---src-pages-home-sections-title-section-title-section-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-not-found-not-found-js": () => import("./../../../src/pages/not-found/not-found.js" /* webpackChunkName: "component---src-pages-not-found-not-found-js" */)
}

